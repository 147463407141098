/* Footer container */
.footer {
  background: #0b409d; /* Background color changed to hover blue */
  color: #fff;
  padding: 2rem 2rem; /* Adjusted padding for smaller height */
}

/* Top section containing company name and social media icons */
.top {
  display: flex;
  flex-direction: column; /* Adjusted to column layout on smaller screens */
  align-items: center; /* Center align items */
  justify-content: center; /* Center content */
}

/* Social media icons */
.top i {
  color: #fff;
  font-size: 2rem;
  margin: 0.5rem; /* Adjusted margin for better spacing */
  transition: color 0.3s; /* Smooth color transition on hover */
}

.top i:hover {
  color: #222; /* Change color on hover */
}

/* Heading */
.top h2 {
  font-size: 1.5rem;
  transition: color 0.3s; /* Smooth color transition on hover */
}

.top h2:hover {
  color: #222; /* Change color on hover */
}

/* Email */
.top p {
  font-size: 1rem;
  transition: color 0.3s; /* Smooth color transition on hover */
}

.top p:hover {
  color: #222; /* Change color on hover */
}

/* Bottom section containing address and other information */
.bottom {
  padding-top: 1rem; /* Adjusted top padding for better spacing */
  display: flex;
  flex-direction: column; /* Adjusted to column layout on smaller screens */
  align-items: center; /* Center align items */
}

/* Individual divs within the bottom section */
.bottom div {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items */
}

/* Headings */
.bottom h4 {
  font-size: 1.3rem;
  padding: 0.8rem 0; /* Adjusted padding for better spacing */
}

/* Links */
.bottom a {
  text-decoration: none;
  color: #fafafa;
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
  transition: color 0.3s; /* Smooth color transition on hover */
}

.bottom a:hover {
  color: #4287ff; /* Change color on hover */
}

/* Media query for smaller screens */
@media screen and (max-width: 850px) {
  .bottom div {
    width: 100%; /* Make each column take up full width on smaller screens */
  }
}

/* Back to Top button */
.back-to-top {
  position: fixed; /* Fixed positioning */
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: #4287ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: none; /* Initially hidden */
}

.back-to-top:hover {
  background-color: #3366cc;
}

/* Show the button when it's visible */
.footer .back-to-top.show {
  display: inline-block;
}
.contact-page {
  padding: 100px 20px; /* Adjusted padding to prevent toolbar overlap */
  background-color: #e0e0e0; /* Background color changed to match container */
}

.contact-heading {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-container {
  background-color: #0b409d; /* Container background color changed to match page */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0; /* Added margin for equal spacing above and below */
}

.info-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.contact-info {
  background-color: #0b409d; /* Change background color to match footer */
  color: #ffffff; /* Change text color to white */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}


.map-container {
  background-color: #fff; /* Adjusted to match background color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
  height: 400px; /* Set desired height */
  width: 100%; /* Set desired width */
}

.map-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.business-hours {
  background-color: #ffffff; /* Container background color changed to match page */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0; /* Added margin for equal spacing above and below */
}

.hours-heading {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

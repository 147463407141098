@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif; /* Change the font-family to Roboto */
}

.App {
  text-align: center;
}

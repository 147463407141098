.about-container {
  padding: 4rem 6rem;
  color: #2a2a2a;
  text-align: start;
}

.about-container h1 {
  padding-bottom: 1rem;
  color: #0b409d;
  font-size: 2.5rem;
}

.about-container h2 {
  padding-bottom: 0.5rem;
  color: #0b409d;
  font-size: 1.8rem;
}

.timer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.25); /* Adjust opacity to 25% */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.about-container p {
  padding-bottom: 2rem;
  font-size: 1.2rem;
}

.about-container .section {
  margin-bottom: 2rem;
}

.about-container img,
.about-container video {
  max-width: 100%;
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 400px; /* Set a fixed height for the slideshow container */
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.slide {
  display: none;
  width: 100%;
}

.slide.active {
  display: block;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width: 850px) {
  .about-container {
    padding: 4rem 2rem;
  }

  .about-container h1 {
    font-size: 2rem;
  }

  .about-container h2 {
    font-size: 1.5rem;
  }

  .about-container p {
    font-size: 1rem;
  }

  .slideshow-container {
    max-width: 400px;
    height: 200px; /* Adjusted height for smaller screens */
  }
}

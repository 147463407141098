.about-container {
    margin: 4rem 6rem;
    color: #2a2a2a;
    text-align: start;
  }
  
  .about-container h1 {
    padding-bottom: 1rem;
  }
  
  .about-container h3 {
    padding-bottom: 0.3rem;
    color: #4287ff;
  }

  .about-container p {
    padding-bottom: 2rem;
  }
  
  @media screen and (max-width: 850px) {
    .about-container {
      margin: 4rem 2rem;
    }
  }

  .fade-in {
    opacity: 0;
    transition: opacity 2.0s ease;
}

.fade-in-animation {
    opacity: 1;
}

.expertise-banner {
  /* background-color: #b0b0b0;  */
  padding: 20px; /* Add padding for spacing */
  margin-bottom: 0px;
}

.expertise-section {
  background-color: #f0f0f0; /* Light grey background color */
  padding: 20px; /* Add padding for spacing */
  margin-bottom: 20px; /* Add margin to separate each section */
}
.about-container {
  padding: 2rem; /* Adjusted padding for better spacing */
  color: #2a2a2a;
  
}

.about-container h2 {
  padding-bottom: 0.5rem; /* Increased padding for subheadings */
  color: #4287ff;
  text-align: center; /* Center-align text */
}

.about-container p {
  padding-bottom: 1rem; /* Adjusted padding for paragraphs */
  font-size: 1rem; /* Decreased font size for paragraphs */
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.about-container img {
  max-width: 100%;
  height: auto; /* Ensure images maintain aspect ratio */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Thick drop shadow */
  margin-bottom: 1rem; /* Adjusted margin for spacing */
}

.apply-now-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4287ff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}



.popup button {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #4287ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 850px) {
  .about-container img,
  .about-container video {
    max-width: 100%;
    height: auto; /* Ensure images and videos maintain aspect ratio */
    margin-bottom: 1rem;
  }

  .button-container {
    margin-top: 20px;
  }
}
